import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScrollTo from 'vue-scrollto'
import VueAnalytics from 'vue-analytics'
import VueMeta from 'vue-meta'
import { firestorePlugin } from 'vuefire'
import VueSplide from '@splidejs/vue-splide';
import '@/style/styles.scss'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';

import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
Vue.use(Toast, {
	position: POSITION.TOP_CENTER,
	offset: '60px',
	closeOnClick: true,
});

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false
Vue.use(VueScrollTo)
Vue.use(VueAnalytics, {
	id: 'UA-181001773-1',
	router,
	disabled: true,
})
Vue.use(firestorePlugin)
Vue.use(VueMeta)
Vue.use( VueSplide );
Vue.use(BootstrapVue);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
