<template>
  <div id="app">
    <fade-transition>
      <router-view />
    </fade-transition>
    <CookieLaw class=".lms-cookielaw" />
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';
import CookieLaw from "./components/CookieLaw.vue"
import VueCookies from 'vue-cookies'

export default {
  components: { FadeTransition, CookieLaw, VueCookies },
  mounted() {
    function generateToken() {
      const timestamp = new Date().getTime();
      const randomNumber = Math.floor(Math.random() * 100000);
      return timestamp.toString() + randomNumber.toString();
    }

    if (localStorage.getItem('cookie:accepted') == "true" && !VueCookies.isKey('cookieId')) {
      const cookieId = generateToken();
      VueCookies.set('cookieId', cookieId, '1y');
    }
    if (localStorage.getItem('cookie:accepted') == "true" && !VueCookies.isKey('sessionId')) {
      console.log("Generate session");
      const sessionId = generateToken();
      VueCookies.set('sessionId', sessionId);
    }
    // if (localStorage.getItem('cookie:accepted') == "false") {
    //   // VueCookies.removeAll();
    //   // document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    //   document.cookie = "";
    // }
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
</style>
