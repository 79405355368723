import Vue from 'vue'
import VueRouter from 'vue-router'
import test from "@/views/test.vue";

// const Login = () => import('./components/authentication/login')
const Home = () => import('@/views/Home.vue')
const Legal = () => import('@/views/Legal.vue')
const Apply = () => import('@/views/Apply.vue')
const ApplicationForm = () => import('@/views/ApplicationForm.vue')
const DatathonApplication = () => import('@/views/DatathonApplication.vue')
const DSDayApplication = () => import('@/views/DSDayApplication.vue')
const FAQ = () => import('@/views/FAQ.vue')
const Team = () => import('@/views/Team.vue')
const Events = () => import('@/views/Events.vue')

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/legal',
		name: 'Legal',
		component: Legal,
	},
	{
		path: '/apply',
		name: 'Apply',
		component: Apply,
	},
	{
		path: '/application',
		name: 'ApplicationForm',
		component: ApplicationForm,
	},
	{
		path: '/blog',
		beforeEnter() {
			location.href = 'https://blog.lumos-consulting.at'
		}
	},
	{
		path: '/datathon',
		name: 'DatathonApplication',
		component: DatathonApplication,
	},
	{
		path: '/dsday',
		name: 'DataScienceDayApplication',
		component: DSDayApplication,
	},
	{
		path: '/faq',
		name: 'FAQ',
		component: FAQ,
	},
	{
		path: '/team',
		name: 'Team',
		component: Team,
	},
	{
		path: '/teamtest',
		name: 'Team Testing',
		//component: TeamTest,
		redirect: '/team'
	},
	{
		path: '/events',
		name: 'Events',
		component: Events,
	},
	{
		path: '/bort',
		name: 'test bort',
		component: test,
	},
	{
		path: '*',
		name: 'Page Not Found',
		redirect: '/',
	},
]

const router = new VueRouter({
	mode: 'history',
	hash: false,
	routes,
	scrollBehavior(to) {
		if (to.hash) {
			return {selector: to.hash}
		} else {
			return {x: 0, y: 0}
		}
	}
})

export default router
