<template>
    <footer>
        <cookie-law>

            <div slot-scope="props" id="overDiv">
                <div class="cookie-icon-container">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff"
                        viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                        <path
                            d="M257.5 27.6c-.8-5.4-4.9-9.8-10.3-10.6c-22.1-3.1-44.6 .9-64.4 11.4l-74 39.5C89.1 78.4 73.2 94.9 63.4 115L26.7 190.6c-9.8 20.1-13 42.9-9.1 64.9l14.5 82.8c3.9 22.1 14.6 42.3 30.7 57.9l60.3 58.4c16.1 15.6 36.6 25.6 58.7 28.7l83 11.7c22.1 3.1 44.6-.9 64.4-11.4l74-39.5c19.7-10.5 35.6-27 45.4-47.2l36.7-75.5c9.8-20.1 13-42.9 9.1-64.9c-.9-5.3-5.3-9.3-10.6-10.1c-51.5-8.2-92.8-47.1-104.5-97.4c-1.8-7.6-8-13.4-15.7-14.6c-54.6-8.7-97.7-52-106.2-106.8zM208 208c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32zm0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32zm160 0c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" />
                    </svg>
                </div>
                <div id="paragraphDiv">
                    <p v-html="getContent.cookieMessages[0][getLanguage]"></p>
                    <p v-html="getContent.cookieMessages[1][getLanguage]"></p>
                </div>
                <div id="buttonsDiv">
                    <button class="skew" @click="accept(props)"><span
                            v-html="getContent.cookieMessages[2][getLanguage]"></span></button>
                    <button class="skew" @click="decline(props)"><span
                            v-html="getContent.cookieMessages[3][getLanguage]"></span></button>
                </div>
            </div>

        </cookie-law>
    </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
export default {
    components: { CookieLaw },
    computed: {
        getLanguage() {
            return this.$store.state.language;
        },
        getContent() {
            return this.$store.state;
        },
    },
    methods: {
        accept(props) {
            this.$ga.enable();
            props.accept();
        },
        decline(props) {
            this.$ga.disable();
            props.decline();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../style/colors.scss";

p {
    color: white;
}

.Cookie {
    background-color: #2C3249;
    color: white;
    padding: 40px;
    justify-content: right;
    width: 600px;
    border-radius: 10px;
    bottom: 20px;
    right: 20px;
    left: auto;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .05), 0 8px 10px -6px rgba(0, 0, 0, .05);
    font-size: 18px;

    @media (max-width: 768px) {
        width: 96%;
        right: auto;
        left: 2%;
        bottom: 2vw;
        padding: 20px;
        font-size: 16px;
    }
}

.cookie-icon-container {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 10px;
    background-color: #252A40;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
    }

    svg {
        fill: white;
    }
}

button {
    color: white;
    border: none;
    padding: 16px 30px;
    margin-top: 4px;
    vertical-align: center;
    border: 1px solid #565B6C;
    border-radius: 16px;

    transition: background-color 0.3s ease-in-out;

    &:first-of-type {
        margin-right: 5px;
        background-color: #343A50;
    }

    &:last-of-type {
        margin-left: 5px;
        background-color: #2C3249;
    }

    &:hover {
        cursor: pointer;
        background-color: #424a65;
    }

    @media (max-width: 768px) {
        padding: 10px 20px;
    }
}

button:active {
    border: 0px;
}

#buttonsDiv {
    // width: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: space-between;
    flex-direction: row;
}

#paragraphDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;

    p {
        text-align: center;
    }
}

#overDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 0;
}
</style>