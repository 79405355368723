<template>
	<div class="idk">

		<h1>{{ getContent.testy.title[getLanguage] }}</h1>
		<p>{{ getContent.testy.subtitle[getLanguage] }}</p>
		<nav-bar class="landing-mode" :nav-settings="navSettings"></nav-bar>
		<p>bortar</p>
		<p>{{ getContent.landing.subtitle[getLanguage] }}</p>

	</div>
</template>


<script>
import NavBar from "@/components/NavBar.vue";

export default {
	components: {NavBar},
	computed: {
		getLanguage() {
			return this.$store.state.language;
		},
		//getContent() {
			//return this.$store.state[this.getLanguage];
		//},
		getContent() {
			return this.$store.state;
		},

		navSettings() {
			// i dont know why this is needed but it is
			return [ {}, {}, {}, {} ]
		},
	},
}
</script>